// extracted by mini-css-extract-plugin
export var tableContainer = "Manual-module--tableContainer--DG3Ne";
export var headerContainer = "Manual-module--headerContainer--Kc-bn";
export var headerTitle = "Manual-module--headerTitle--80C8+";
export var itemContainer = "Manual-module--itemContainer--ENZQx";
export var headerTitleMobile = "Manual-module--headerTitleMobile--cmDcZ";
export var date = "Manual-module--date--kf8qA";
export var versionText = "Manual-module--versionText--zUQXX";
export var readNote = "Manual-module--readNote--s2VP4";
export var downloadButtonContainer = "Manual-module--downloadButtonContainer--DUv3A";
export var downloadButton = "Manual-module--downloadButton--c3kzQ";
export var iconContainer = "Manual-module--iconContainer--oKn6p";
export var showMoreButtonContainer = "Manual-module--showMoreButtonContainer--pklwh";
export var showMoreButton = "Manual-module--showMoreButton--q6m2o";