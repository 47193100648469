import { ReactElement } from "react";
import CtaLink from "../../../Shared/CtaLink";
import * as styles from "./SoftwareSupport.module.scss";
import { arrows } from "../../../Icons";
import removeWhiteSpaces from "../../../../shared/RemoveWhiteSpaces";

export interface ISoftwareSupport {
    widgetProps: GatsbyTypes.strapi_ComponentWidgetsSupport;
    product: GatsbyTypes.strapi_Product;
    supportTypes: GatsbyTypes.strapi_SupportTypes[] | undefined;
}

export default function SoftwareSupport({ widgetProps, product, supportTypes }: ISoftwareSupport): ReactElement {
    const technicalSupportArgs = {
        supportType:
            supportTypes?.sort((a, b) => (a.product_types?.length ?? 0) - (b.product_types?.length ?? 0))?.find(x => x.product_types?.some(y => y?.id === product?.product_type?.id))?.name ?? "",
        product: encodeURIComponent(removeWhiteSpaces(product?.name) ?? ""),
    };

    return (
        <div className={styles.softwareSupport}>
            <div className={`mainBox ${styles.inner}`}>
                <div>
                    {widgetProps && (
                        <>
                            <h1>{widgetProps?.base?.heading}</h1> <div dangerouslySetInnerHTML={{ __html: widgetProps?.base?.paragraph ?? "" }}></div>
                        </>
                    )}
                </div>
                <div className={styles.linkSupport}>
                    {widgetProps && <CtaLink cta={widgetProps.cta as GatsbyTypes.strapi_ComponentSharedCtaContent} args={technicalSupportArgs} />}
                    {arrows.right}
                </div>
            </div>
        </div>
    );
}
