import * as styles from "./AccordionDrawer.module.scss";

interface IAccordionDrawerProps {
    children: any;
    handleChangeAccordion: (name: string) => void;
    id: string;
    open: boolean;
    title: string;
}

function AccordionDrawer({ children, handleChangeAccordion, id, open, title }: IAccordionDrawerProps) {
    return (
        <div id={id} className={`${styles.accordionItem} ${open ? styles.expand : styles.collapse}`}>
            <div onClick={() => handleChangeAccordion(id)} className={`${styles.titleFlex} mainBox`}>
                <h1 className={styles.title}>{title}</h1>
                <button className={styles.title}>{open ? "-" : "+"}</button>
            </div>
            <div className={`${styles.accordion} ${open ? styles.expand : styles.collapse}`}>{children}</div>
        </div>
    );
}

export default AccordionDrawer;
