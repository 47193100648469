// extracted by mini-css-extract-plugin
export var installerGrid = "Installer-module--installerGrid--ruGst";
export var titleFlex = "Installer-module--titleFlex--hqaxy";
export var title = "Installer-module--title--Fu-q4";
export var tableContainer = "Installer-module--tableContainer--rqY68";
export var headerContainer = "Installer-module--headerContainer--aCC5u";
export var headerTitle = "Installer-module--headerTitle--FovYL";
export var itemContainer = "Installer-module--itemContainer--2BU8M";
export var description = "Installer-module--description--yELfM";
export var headerTitleMobile = "Installer-module--headerTitleMobile--QC3Ow";
export var date = "Installer-module--date--eurxe";
export var versionSpan = "Installer-module--versionSpan--ArLLt";
export var readNote = "Installer-module--readNote--TY+Ln";
export var downloadButtonContainer = "Installer-module--downloadButtonContainer--CHlsP";
export var downloadButton = "Installer-module--downloadButton--6jpXo";
export var iconContainer = "Installer-module--iconContainer--S39zF";
export var showMoreButtonContainer = "Installer-module--showMoreButtonContainer--omBLm";
export var showMoreButton = "Installer-module--showMoreButton--DYQOw";