import { graphql, useStaticQuery } from "gatsby";
import { ReactElement, useEffect, useState } from "react";
import { useDependency } from "../../../../contexts/DependencyContext";
import { DownloadsService } from "../../../../services/http/DownloadsService";
import { downloadBlueArrow } from "../../../Icons";
import { downloadLogo } from "../../../Icons";
import * as styles from "./Perpetual.module.scss";

interface IPerpetual {
    widgetProps: GatsbyTypes.strapi_ComponentDownloadPerpetual[];
    id: number;
}

export default function Perpetual({ widgetProps, id }: IPerpetual): ReactElement {
    const [perpetuals, setPerpetuals] = useState(widgetProps);
    const [visible, setVisible] = useState(true);
    const downloadsService = useDependency(DownloadsService);
    const text = useStaticQuery<GatsbyTypes.Query>(query);

    useEffect(() => {
        setPerpetuals(widgetProps.slice(0, 3));
        setVisible(widgetProps.length >= 4);
    }, []);

    async function getAllPerpetual() {
        const allPerpetual = await downloadsService.getAllPerpetualAsync(id);
        setPerpetuals(allPerpetual);
        setVisible(false);
    }

    const generateBorderBottom = (i: number) => (i + 1 !== perpetuals?.length ? { borderBottom: "1px solid #515151" } : {});
    const perpetual = text.strapi.downloadGridText?.perpetual as GatsbyTypes.strapi_ComponentWidgetsTextPerpetual;

    return (
        <>
            <div className="mainBox">
                <div className={styles.tableContainer}>
                    <div className={styles.headerContainer}>
                        <div className={styles.headerTitle}>{perpetual.versionTitle}</div>
                        <div className={styles.headerTitle}>{perpetual.releaseDateTitle}</div>
                        <div className={styles.headerTitle}>{perpetual.releaseNoteTitle}</div>
                    </div>
                    <div>
                        {perpetuals &&
                            perpetuals.map((x, i) => (
                                <div style={generateBorderBottom(i)} key={x.id} className={styles.itemContainer}>
                                    <div>
                                        <div className={styles.headerTitleMobile}>{perpetual.versionTitle}</div>
                                        <span className={styles.versionSpan}>{x.base?.heading}</span>
                                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: x.base?.paragraph ?? "" }}></div>
                                    </div>

                                    <div>
                                        <div className={styles.headerTitleMobile}>{perpetual.releaseDateTitle}</div>
                                        <div className={styles.date}>{x.date}</div>
                                    </div>

                                    <div>
                                        {x.note && x.note?.link.length > 0 && (
                                            <>
                                                <div className={styles.headerTitleMobile}>{perpetual.releaseNoteTitle}</div>
                                                <a href={x.note?.link}>
                                                    <div className={styles.readNote}>{x.note?.text}</div>
                                                </a>
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        <div className={styles.downloadButtonContainer}>
                                            {x.linkFile && x.linkFile.length > 0 && (
                                                <a href={x.linkFile}>
                                                    <div className={styles.downloadButton}>
                                                        <p>{perpetual.btnText}</p>
                                                        <div className={styles.downloadButton}>{downloadLogo}</div>
                                                    </div>
                                                </a>
                                            )}
                                            {x.file && x.file.url && (
                                                <a href={x.file.url}>
                                                    <div className={styles.downloadButton}>
                                                        <p>{perpetual.btnText}</p>
                                                        <div className={styles.downloadButton}>{downloadLogo}</div>
                                                    </div>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                {visible && (
                    <div className={styles.showMoreButtonContainer}>
                        <button className={styles.showMoreButton} onClick={getAllPerpetual}>
                            {text.strapi.downloadGridText?.btnShowMoreText}
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}

const query = graphql`
    query {
        strapi {
            downloadGridText {
                btnShowMoreText
                perpetual {
                    mainTitle
                    btnText
                    releaseDateTitle
                    releaseNoteTitle
                    versionTitle
                }
            }
        }
    }
`;
