import { graphql, useStaticQuery } from "gatsby";
import { ReactElement, useEffect, useState } from "react";
import { useDependency } from "../../../../contexts/DependencyContext";
import { DownloadsService } from "../../../../services/http/DownloadsService";
import { downloadLogo } from "../../../Icons";
import * as styles from "./Manual.module.scss";

interface IManual {
    widgetProps: GatsbyTypes.strapi_ComponentDownloadManual[];
    id: number;
}

export default function Manual({ widgetProps, id }: IManual): ReactElement {
    const [manuals, setManuals] = useState(widgetProps);
    const [visible, setVisible] = useState(true);
    const downloadsService = useDependency(DownloadsService);
    const text = useStaticQuery<GatsbyTypes.Query>(query);

    useEffect(() => {
        const execute = async () => {
            setManuals(widgetProps.slice(0, 3));
            setVisible(widgetProps.length >= 4);
        };

        execute();
    }, []);

    async function getAllManuals(): Promise<void> {
        const allManuals = await downloadsService.getAllManualsAsync(id);
        setManuals(allManuals);
        setVisible(false);
    }

    const generateBorderBottom = (i: number) => (i + 1 !== manuals?.length ? { borderBottom: "1px solid #515151" } : {});
    const manual = text.strapi.downloadGridText?.manual as GatsbyTypes.strapi_ComponentWidgetsTextManual;

    return (
        <>
            <div className="mainBox">
                <div className={styles.tableContainer}>
                    <div className={styles.headerContainer}>
                        <div className={styles.headerTitle}>{manual.versionTitle}</div>
                        <div className={styles.headerTitle}>{manual.dateTitle}</div>
                    </div>
                    <div>
                        {manuals &&
                            manuals.map((x, i) => (
                                <div style={generateBorderBottom(i)} key={x.id} className={styles.itemContainer}>
                                    <div>
                                        <div className={styles.headerTitleMobile}>{manual.versionTitle}</div>
                                        <div className={styles.versionText}>Manual for Version {x.version}</div>
                                    </div>

                                    <div>
                                        <div className={styles.headerTitleMobile}>{manual.dateTitle}</div>
                                        <div className={styles.date}>{x.date}</div>
                                    </div>
                                    <div>
                                        <div className={styles.downloadButtonContainer}>
                                            {x.linkFile && x.linkFile.length > 0 && (
                                                <a href={x.linkFile} target="_blank">
                                                    <div className={styles.downloadButton}>
                                                        <p>{manual.btnText}</p>
                                                        <div className={styles.iconContainer}>{downloadLogo}</div>
                                                    </div>
                                                </a>
                                            )}
                                            {x.file && x.file.url && (
                                                <a href={x.file.url} target="_blank">
                                                    <div className={styles.downloadButton}>
                                                        <p>{manual.btnText}</p>
                                                        <div className={styles.downloadButton}>{downloadLogo}</div>
                                                    </div>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                {visible && (
                    <div className={styles.showMoreButtonContainer}>
                        <button className={styles.showMoreButton} onClick={getAllManuals}>
                            {text.strapi.downloadGridText?.btnShowMoreText}
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}

const query = graphql`
    query {
        strapi {
            downloadGridText {
                btnShowMoreText
                manual {
                    btnText
                    dateTitle
                    mainTitle
                    versionTitle
                }
            }
        }
    }
`;
