import { ReactElement } from "react";
import * as styles from "./Generic.module.scss";
import { downloadLogo } from "../../../Icons";

interface IGeneric {
    widgetProps: GatsbyTypes.strapi_ComponentDownloadTextGenericDownload;
}

export default function Generic({ widgetProps }: IGeneric): ReactElement {
    return (
        <div className="mainBox">
            <div key={widgetProps.id} className={styles.genericDownloads}>
                <div dangerouslySetInnerHTML={{ __html: widgetProps.base?.paragraph ?? "" }} key={widgetProps.id} className={styles.description}></div>
                <>
                    <div className={styles.headerContainer} key={widgetProps.id}>
                        <div key={`vers${widgetProps.id}`} className={styles.headerTitle}>
                            {widgetProps.versionTitle}
                        </div>
                        <div key={`date${widgetProps.id}`} className={styles.headerTitle}>
                            {widgetProps.dateTitle}
                        </div>
                        <div key={`note${widgetProps.id}`} className={styles.headerTitle}>
                            {widgetProps.noteTitle}
                        </div>
                    </div>

                    {widgetProps.genericContent?.map(x => (
                        <div className={styles.itemContainer} key={`grid${widgetProps.id}`}>
                            <div>
                                <div key={`vers${widgetProps.id}`} className={styles.headerTitleMobile}>
                                    {widgetProps.versionTitle}
                                </div>
                                <div key={`version${x?.id}`}>{x?.version}</div>
                            </div>

                            <div>
                                <div key={`date${widgetProps.id}`} className={styles.headerTitleMobile}>
                                    {widgetProps.dateTitle}
                                </div>
                                <div key={`date${x?.id}`}>{x?.date}</div>
                            </div>

                            <div>
                                {x?.ctaNote && x.ctaNote?.link.length > 0 && (
                                    <>
                                        <div key={`note${widgetProps.id}`} className={styles.headerTitleMobile}>
                                            {widgetProps.noteTitle}
                                        </div>
                                        <a href={x?.ctaNote?.link} key={`link${x?.id}`} className={styles.readNote}>
                                            <div key={`note${x?.id}`}>{x?.ctaNote?.text}</div>
                                        </a>
                                    </>
                                )}
                            </div>

                            {x?.linkFile && x.linkFile.length > 0 && (
                                <div className={styles.downloadButtonContainer}>
                                    <a href={x.linkFile}>
                                        <div className={styles.downloadButton}>
                                            <p>{widgetProps.btnText}</p>
                                            <div className={styles.iconContainer}>{downloadLogo}</div>
                                        </div>
                                    </a>
                                </div>
                            )}
                            {x?.file && x.file.url && (
                                <div className={styles.downloadButtonContainer}>
                                    <a href={x.file.url}>
                                        <div className={styles.downloadButton}>
                                            <p>{widgetProps.btnText}</p>
                                            <div className={styles.iconContainer}>{downloadLogo}</div>
                                        </div>
                                    </a>
                                </div>
                            )}
                        </div>
                    ))}
                </>
            </div>
        </div>
    );
}
