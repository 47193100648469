import { ContextCollection } from "../../contexts/DependencyContext";
import { StrapiClient } from "./clients/StrapiClient";

export class DownloadsService {
    private readonly url: string;

    constructor(private readonly apiClient: StrapiClient) {
        this.url = "downloads";
    }

    async getAllInstallersAsync(id: number): Promise<GatsbyTypes.strapi_ComponentDownloadInstaller[]> {
        return await this.apiClient.get<GatsbyTypes.strapi_ComponentDownloadInstaller[]>(`${this.url}/allinstallers/${id}`);
    }

    async getAllManualsAsync(id: number): Promise<GatsbyTypes.strapi_ComponentDownloadManual[]> {
        return await this.apiClient.get<GatsbyTypes.strapi_ComponentDownloadManual[]>(`${this.url}/allmanuals/${id}`);
    }

    async getAllOtherFilesAsync(id: number): Promise<GatsbyTypes.strapi_ComponentDownloadOtherFiles[]> {
        return await this.apiClient.get<GatsbyTypes.strapi_ComponentDownloadOtherFiles[]>(`${this.url}/allotherfiles/${id}`);
    }

    async getAllPerpetualAsync(id: number): Promise<GatsbyTypes.strapi_ComponentDownloadPerpetual[]> {
        return await this.apiClient.get<GatsbyTypes.strapi_ComponentDownloadPerpetual[]>(`${this.url}/allperpetual/${id}`);
    }

    async getAllActivationAsync(id: number): Promise<GatsbyTypes.strapi_ComponentDownloadActivation[]> {
        return await this.apiClient.get<GatsbyTypes.strapi_ComponentDownloadActivation[]>(`${this.url}/allactivation/${id}`);
    }
}

ContextCollection.registerSingleton(DownloadsService, [StrapiClient]);
