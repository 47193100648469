// extracted by mini-css-extract-plugin
export var titleFlex = "Perpetual-module--titleFlex--3XyjL";
export var title = "Perpetual-module--title--glyfK";
export var tableContainer = "Perpetual-module--tableContainer--rriB1";
export var headerContainer = "Perpetual-module--headerContainer--WTGAb";
export var headerTitle = "Perpetual-module--headerTitle--xQJaF";
export var itemContainer = "Perpetual-module--itemContainer--ujgsY";
export var description = "Perpetual-module--description--5nJ0A";
export var headerTitleMobile = "Perpetual-module--headerTitleMobile--1q30M";
export var date = "Perpetual-module--date--7Sr60";
export var versionSpan = "Perpetual-module--versionSpan--gfztG";
export var readNote = "Perpetual-module--readNote--sM632";
export var downloadButtonContainer = "Perpetual-module--downloadButtonContainer--T4645";
export var downloadButton = "Perpetual-module--downloadButton--B5fFS";
export var iconContainer = "Perpetual-module--iconContainer--M-ANX";
export var showMoreButtonContainer = "Perpetual-module--showMoreButtonContainer--289YT";
export var showMoreButton = "Perpetual-module--showMoreButton--prEmy";