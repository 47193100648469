import { graphql, useStaticQuery } from "gatsby";
import { ReactElement, useEffect, useState } from "react";
import { useDependency } from "../../../../contexts/DependencyContext";
import { DownloadsService } from "../../../../services/http/DownloadsService";
import { downloadLogo } from "../../../Icons";
import * as styles from "./OtherFiles.module.scss";

interface IOtherFiles {
    widgetProps: GatsbyTypes.strapi_ComponentDownloadOtherFiles[];
    id: number;
}

export default function OtherFiles({ widgetProps, id }: IOtherFiles): ReactElement {
    const [otherFiles, setOtherFiles] = useState<GatsbyTypes.strapi_ComponentDownloadOtherFiles[]>();
    const [visible, setVisible] = useState(true);
    const downloadsService = useDependency(DownloadsService);
    const text = useStaticQuery<GatsbyTypes.Query>(query);

    useEffect(() => {
        setOtherFiles(widgetProps.slice(0, 3));
        setVisible(widgetProps.length >= 4);
    }, []);

    async function getAllOtherFiles() {
        const allOtherFiles = await downloadsService.getAllOtherFilesAsync(id);
        setOtherFiles(allOtherFiles);
        setVisible(false);
    }

    const generateBorderBottom = (i: number) => (i + 1 !== otherFiles?.length ? { borderBottom: "1px solid #515151" } : {});

    const otherFilesText = text.strapi.downloadGridText?.otherFiles as GatsbyTypes.strapi_ComponentWidgetsTextOtherFiles;

    return (
        <div>
            <div className="mainBox">
                <div className={styles.tableContainer}>
                    <div className={styles.headerContainer}>
                        <div className={styles.headerTitle}>{otherFilesText.versionTitle}</div>
                        <div className={styles.headerTitle}>{otherFilesText.dateTitle}</div>
                        <div className={styles.headerTitle}>{otherFilesText.fileTypeTitle}</div>
                    </div>
                    <div>
                        {otherFiles &&
                            otherFiles.map((x, i) => (
                                <div style={generateBorderBottom(i)} key={x.id} className={styles.itemContainer}>
                                    <div>
                                        <div className={styles.headerTitleMobile}>{otherFilesText.versionTitle}</div>
                                        <h4 className={styles.versionText}>{x.base?.heading}</h4>
                                        <h5 className={styles.description} dangerouslySetInnerHTML={{ __html: x.base?.paragraph ?? "" }} />
                                    </div>

                                    <div>
                                        <div className={styles.headerTitleMobile}>{otherFilesText.dateTitle}</div>
                                        <div className={styles.date}>{x.date}</div>
                                    </div>

                                    <div>
                                        <div className={styles.headerTitleMobile}>{otherFilesText.fileTypeTitle}</div>
                                        <div>{x.type}</div>
                                    </div>

                                    <div>
                                        <div className={styles.downloadButtonContainer}>
                                            {x.linkFile && x.linkFile.length > 0 && (
                                                <a href={x.linkFile}>
                                                    <div className={styles.downloadButton}>
                                                        <p>{otherFilesText.btnText}</p>
                                                        <div className={styles.iconContainer}>{downloadLogo}</div>
                                                    </div>
                                                </a>
                                            )}
                                            {x.file && x.file.url && (
                                                <a href={x.file.url}>
                                                    <div className={styles.downloadButton}>
                                                        <p>{otherFilesText.btnText}</p>
                                                        <div className={styles.downloadButton}>{downloadLogo}</div>
                                                    </div>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                {visible && (
                    <div className={styles.showMoreButtonContainer} onClick={getAllOtherFiles}>
                        <button className={styles.showMoreButton}>{text.strapi.downloadGridText?.btnShowMoreText}</button>
                    </div>
                )}
            </div>
        </div>
    );
}

const query = graphql`
    query {
        strapi {
            downloadGridText {
                btnShowMoreText
                otherFiles {
                    btnText
                    mainTitle
                    dateTitle
                    versionTitle
                    fileTypeTitle
                }
            }
        }
    }
`;
