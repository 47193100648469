// extracted by mini-css-extract-plugin
export var genericDownloads = "Generic-module--genericDownloads--BqMF1";
export var description = "Generic-module--description--3LAm1";
export var headerContainer = "Generic-module--headerContainer--gTKeA";
export var headerTitle = "Generic-module--headerTitle--hn5sQ";
export var itemContainer = "Generic-module--itemContainer--+YQQC";
export var headerTitleMobile = "Generic-module--headerTitleMobile--jGiJd";
export var date = "Generic-module--date--vLMfd";
export var versionText = "Generic-module--versionText--fC2ZB";
export var readNote = "Generic-module--readNote--OHigM";
export var downloadButtonContainer = "Generic-module--downloadButtonContainer--8BNBY";
export var downloadButton = "Generic-module--downloadButton--PZUR4";
export var iconContainer = "Generic-module--iconContainer--WsvV3";
export var showMoreButtonContainer = "Generic-module--showMoreButtonContainer--axzC6";
export var showMoreButton = "Generic-module--showMoreButton--C5yRF";