import { CSSProperties, ReactElement } from "react";
import { ddcToolboxIcon, arrows, heroImage } from "../../../components/Icons";
import * as styles from "./Hero.module.scss";

interface IHeroProps {
    title: string;
    color: string;
    installerInfo: { link: string; version: string };
    manualLink: string;
    downloadGridText: GatsbyTypes.strapi_DownloadGridText;
    withProduct: boolean;
}

export default function Hero({ title, color, downloadGridText, installerInfo, manualLink, withProduct }: IHeroProps): ReactElement {
    return (
        <div className={styles.downloadHeader} style={{ "--product-color": color } as CSSProperties}>
            <div className="mainBox">
                <div className={styles.titleContainer}>
                    <div>
                        {ddcToolboxIcon}
                        <h1>{title}</h1>
                    </div>
                </div>
                {withProduct && (
                    <div>
                        {installerInfo.link != "" && <p>{downloadGridText?.latestInstallerText}</p>}

                        <div className={styles.headerButtons}>
                            <div className={styles.downloadButton}>
                                {installerInfo.link !== "" && (
                                    <>
                                        <a href={installerInfo.link} target="blank_">
                                            <button>
                                                <div>
                                                    {downloadGridText?.btnLatestInstallerText}
                                                    {installerInfo.version && (
                                                        <span>
                                                            {downloadGridText.textVersionBtn} {installerInfo.version}
                                                        </span>
                                                    )}
                                                </div>

                                                <div>{arrows.down}</div>
                                            </button>
                                        </a>
                                    </>
                                )}
                            </div>
                            <div className={styles.manualButton}>
                                {manualLink && (
                                    <a href={manualLink} target="_blank">
                                        <button>
                                            <div>{downloadGridText?.btnLatestManualText}</div>
                                            <div>{arrows.down}</div>
                                        </button>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.headerImage}>{heroImage}</div>
            </div>
        </div>
    );
}
