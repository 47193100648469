import { ReactElement, useEffect, useState } from "react";
import { graphql, navigate, PageProps } from "gatsby";
import { useDependency } from "../../contexts/DependencyContext";
import DownloadWidgetService, { WidgetProps } from "../../services/widgets/DownloadWidgetService";
import Helmet from "../../components/Helmet";
import AccordionDrawer from "../../components/Widgets/AccordionDrawer/AccordionDrawer";
import Generic from "../../components/Widgets/DownloadWidgets/Generic";
import SoftwareSupport from "../../components/Widgets/DownloadWidgets/SoftwareSupport";
import Hero from "./Hero";

interface IDownloadProps extends PageProps {
    data: GatsbyTypes.DownloadQuery;
}

type Dictionary = { [key: string]: boolean };

const knownSections = ["installer", "manual", "otherFiles", "perpetual", "activation"];
const knownKey = "known_";
const genericKey = "generic";

function getNewestInstaller(sortedInstallers: any[]): { link: string; version: string } {
    const installer = sortedInstallers[0] as GatsbyTypes.strapi_ComponentDownloadInstaller;
    sortedInstallers?.splice(0, 1);
    return {
        link: installer?.linkFile && installer?.linkFile?.length > 0 ? installer?.linkFile : installer?.file?.url ?? "",
        version: installer?.version ?? "",
    };
}

function getNewestManual(sortedManuals: any[]): string {
    const manual = sortedManuals[0] as GatsbyTypes.strapi_ComponentDownloadManual;
    sortedManuals?.splice(0, 1);
    return manual?.linkFile && manual.linkFile?.length > 0 ? manual?.linkFile : manual?.file?.url ?? "";
}

function arrayToDictionary(array: string[], prefix?: string, value: boolean = false): Dictionary {
    return array.reduce((p, c) => {
        p[`${prefix}${c}`] = value;
        return p;
    }, {} as Dictionary);
}

export default function Download({ data, location }: IDownloadProps): ReactElement {
    /**************************************************************
     * We are unifying the known fixed sections installer, manuals, etc, with
     * dynamic generic zones. For that reason, we need to join both ids into
     * an initial state object
     ***************************************************************/
    const widgetsData = data.strapi.download;
    const generics = widgetsData?.generic as GatsbyTypes.strapi_ComponentDownloadTextGenericDownload[];
    const genericSections = generics.map(x => x.id);
    const initialState = {
        ...arrayToDictionary(knownSections, knownKey),
        ...arrayToDictionary(genericSections, genericKey),
    };

    const [accordionSections, setAccordionSections] = useState<Dictionary>({ ...initialState });
    const [installerInfo, setInstallerInfo] = useState({ link: "", version: "" });
    const [manualLink, setManualLink] = useState("");
    const widgetService = useDependency(DownloadWidgetService);

    const sortedInstallers = [...(widgetsData?.installer as any[])?.sort((a, b) => b.date - a.date)];
    const sortedManuals = [...(widgetsData?.manual as any[])?.sort((a, b) => b.date - a.date)];
    const support = data.strapi?.downloadGridText?.support;
    const widgetsDataKeys = ["installer", "manual", "otherFiles", "perpetual", "activation"];
    const download = data.strapi?.download as GatsbyTypes.strapi_Download;
    const downloadGridText = data.strapi?.downloadGridText as GatsbyTypes.strapi_DownloadGridText;
    const product = download?.product as GatsbyTypes.strapi_Product;
    const supportTypes = data.strapi.supportTypes as GatsbyTypes.strapi_SupportTypes[];
    const withProduct = data.strapi.download?.product != null ? true : false;

    useEffect(() => {
        setInstallerInfo(getNewestInstaller(sortedInstallers));
        setManualLink(getNewestManual(sortedManuals));
    }, []);

    useEffect(() => {
        if (location.hash) {
            const slicedHash = location.hash.slice(1);
            setAccordionSections({ ...initialState, [slicedHash]: true });
        }
    }, [location]);

    async function handleChangeAccordionAsync(value: string): Promise<void> {
        if (!accordionSections[value]) await navigate("./#" + value);
        setAccordionSections({ ...initialState, [value]: !accordionSections[value] });
    }

    return (
        <>
            <Helmet title={`Download - ${download?.product?.name ?? download?.name}`} description={""} />
            <section>
                <Hero
                    title={download?.product?.name ?? download?.name ?? ""}
                    color={product?.color}
                    installerInfo={installerInfo}
                    manualLink={manualLink}
                    downloadGridText={downloadGridText as GatsbyTypes.strapi_DownloadGridText}
                    withProduct={withProduct}
                />

                <div>
                    {widgetsDataKeys?.map(x => {
                        const widgetData = (widgetsData as any)[x] as WidgetProps;
                        const title = (downloadGridText as any)?.[x]?.mainTitle ?? "";
                        const widget = widgetService.getWidget(x, widgetData, product, supportTypes, parseInt(widgetsData?.id ?? "0"));

                        return (
                            (((x === "installer" || x === "manual") && widgetsData?.installer && widgetsData?.installer.length > 1 && widget) || (x != "installer" && x != "manual" && widget)) && (
                                <AccordionDrawer key={x} open={accordionSections[x]} title={title} id={x} handleChangeAccordion={handleChangeAccordionAsync}>
                                    {widget}
                                </AccordionDrawer>
                            )
                        );
                    })}

                    {generics?.map(x => {
                        const key = `${genericKey}${x.id}`;

                        return (
                            <AccordionDrawer key={x.id} open={accordionSections[key]} title={x.base?.heading ?? ""} id={key} handleChangeAccordion={handleChangeAccordionAsync}>
                                <Generic key={x.id} widgetProps={x}></Generic>
                            </AccordionDrawer>
                        );
                    })}
                </div>

                <div>{support && <SoftwareSupport widgetProps={support as GatsbyTypes.strapi_ComponentWidgetsSupport} product={product} supportTypes={supportTypes} />}</div>
            </section>
        </>
    );
}

export const query = graphql`
    query Download($id: ID!) {
        strapi {
            download(id: $id) {
                id
                name
                product {
                    name
                    color
                    product_type {
                        id
                        name
                    }
                }
                installer {
                    __typename
                    description
                    note {
                        text
                        link
                    }
                    date
                    id
                    version
                    linkFile
                    file {
                        url
                    }
                }
                otherFiles {
                    __typename
                    date
                    id
                    base {
                        heading
                        paragraph
                    }
                    type
                    linkFile
                    file {
                        url
                    }
                }
                manual {
                    date
                    id
                    version
                    linkFile
                    file {
                        url
                    }
                }
                perpetual {
                    id
                    base {
                        id
                        heading
                        paragraph
                    }
                    note {
                        text
                        link
                    }
                    version
                    date
                    file {
                        url
                    }
                }
                activation {
                    id
                    base {
                        id
                        heading
                        paragraph
                    }
                    version
                    file {
                        url
                    }
                }
                generic {
                    base {
                        heading
                        paragraph
                        id
                    }
                    btnText
                    dateTitle
                    genericContent {
                        date
                        id
                        version
                        ctaNote {
                            link
                            text
                            external
                        }
                        file {
                            url
                        }
                    }
                    id
                    noteTitle
                    versionTitle
                }
            }
            downloadGridText {
                btnLatestInstallerText
                btnLatestManualText
                latestInstallerText
                textVersionBtn
                support {
                    id
                    cta {
                        external
                        link
                        text
                    }
                    base {
                        heading
                        paragraph
                    }
                }
                installer {
                    mainTitle
                }
                manual {
                    mainTitle
                }
                otherFiles {
                    mainTitle
                }
                perpetual {
                    mainTitle
                }
                activation {
                    mainTitle
                }
                hero {
                    base {
                        heading
                    }
                    image {
                        url
                        url_sharp {
                            childImageSharp {
                                gatsbyImageData(quality: 60)
                            }
                        }
                    }
                }
            }
            supportTypes {
                id
                name
                product_types {
                    id
                    name
                }
            }
        }
    }
`;
