import { ReactElement } from "react";
import Activation from "../../components/Widgets/DownloadWidgets/Activation";
import Installer from "../../components/Widgets/DownloadWidgets/Installer";
import Manual from "../../components/Widgets/DownloadWidgets/Manual";
import OtherFiles from "../../components/Widgets/DownloadWidgets/OtherFiles";
import Perpetual from "../../components/Widgets/DownloadWidgets/Perpetual";
import SoftwareSupport from "../../components/Widgets/DownloadWidgets/SoftwareSupport";
import { ContextCollection } from "../../contexts/DependencyContext";

export type WidgetProps =
    | GatsbyTypes.strapi_ComponentDownloadInstaller[]
    | GatsbyTypes.strapi_ComponentDownloadManual[]
    | GatsbyTypes.strapi_ComponentDownloadOtherFiles[]
    | GatsbyTypes.strapi_ComponentDownloadPerpetual[];

export default class DownloadWidgetService {
    public getType(key: string): string {
        return key?.toLowerCase();
    }

    public getWidget(key: string, widgetProps: WidgetProps, product: GatsbyTypes.strapi_Product, supportTypes: GatsbyTypes.strapi_SupportTypes[], id: number): ReactElement | undefined {
        const type = this.getType(key);

        if (widgetProps && widgetProps.hasOwnProperty("length") && (widgetProps as any)["length"] === 0) return;

        switch (type) {
            case "installer":
                return <Installer widgetProps={widgetProps as GatsbyTypes.strapi_ComponentDownloadInstaller[]} id={id} />;

            case "manual":
                return <Manual widgetProps={widgetProps as GatsbyTypes.strapi_ComponentDownloadManual[]} id={id} />;

            case "otherfiles":
                return <OtherFiles widgetProps={widgetProps as GatsbyTypes.strapi_ComponentDownloadOtherFiles[]} id={id} />;

            case "perpetual":
                return <Perpetual widgetProps={widgetProps as GatsbyTypes.strapi_ComponentDownloadPerpetual[]} id={id} />;

            case "activation":
                return <Activation widgetProps={widgetProps as GatsbyTypes.strapi_ComponentDownloadActivation[]} id={id} />;

            default:
                return;
        }
    }
}

ContextCollection.registerTransient(DownloadWidgetService);
