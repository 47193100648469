// extracted by mini-css-extract-plugin
export var tableContainer = "OtherFiles-module--tableContainer--cv4Fu";
export var headerContainer = "OtherFiles-module--headerContainer--GxJcz";
export var headerTitle = "OtherFiles-module--headerTitle--cUA60";
export var itemContainer = "OtherFiles-module--itemContainer--bzkAo";
export var headerTitleMobile = "OtherFiles-module--headerTitleMobile--1WUhk";
export var date = "OtherFiles-module--date--Ohnd-";
export var description = "OtherFiles-module--description--h5C1T";
export var versionText = "OtherFiles-module--versionText--BH0Vw";
export var readNote = "OtherFiles-module--readNote--FLFXr";
export var downloadButtonContainer = "OtherFiles-module--downloadButtonContainer--arzJw";
export var downloadButton = "OtherFiles-module--downloadButton--as2Yq";
export var iconContainer = "OtherFiles-module--iconContainer--El1Zz";
export var showMoreButtonContainer = "OtherFiles-module--showMoreButtonContainer--Z5sE2";
export var showMoreButton = "OtherFiles-module--showMoreButton--S4kXD";